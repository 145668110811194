import "./safemail";
/* Burger */

document.addEventListener('DOMContentLoaded', () => {

    // Get all "navbar-burger" elements
    const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);
  
    // Check if there are any navbar burgers
    if ($navbarBurgers.length > 0) {
  
        // Add a click event on each of them
        $navbarBurgers.forEach( el => {
            el.addEventListener('click', () => {
    
            // Get the target from the "data-target" attribute
            const target = el.dataset.target;
            const $target = document.getElementById(target);
    
            // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
            el.classList.toggle('is-active');
            $target.classList.toggle('is-active');
    
            });
        });
    }
  
});

/* Goto Top button */
window.onscroll = function() {scrollFunction()};

document.getElementById("top_btn").addEventListener('click', () => {gotoTop();});

function scrollFunction() {
    top_btn = document.getElementById("top_btn");
  if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
    top_btn.classList.remove("is-hidden");
  } else {
    top_btn.classList.add("is-hidden");
  }
}

// When the user clicks on the button, scroll to the top of the document
function gotoTop() {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
}

/* Nav */

document.getElementById("nav_home").addEventListener('click', () => {gotoSection("home_section");});
document.getElementById("nav_services").addEventListener('click', () => {gotoSection("services_section");});
document.getElementById("nav_contact").addEventListener('click', () => {gotoSection("contact_section");});

function gotoSection(section_name){
    window.scrollTo(0, document.getElementById(section_name).offsetTop);
    // let nav_btn_name = section_name.split("_section")[0];
    // document.getElementById("nav_home").classList.remove("is-active");
    // document.getElementById("nav_services").classList.remove("is-active");
    // document.getElementById("nav_contact").classList.remove("is-active");
    // let nav_items = document.getElementById("navbarMenuHeroB").getElementsByClassName("navbar-item");
    // for (let i = 0; i < nav_items.length; i++) {
    //     console.log(i, nav_items[i].id);
    //     if (nav_items[i].id == "nav_" + nav_btn_name) {
    //         document.getElementById(nav_items[i].id).classList.add("is-active");
    //     } else {
    //         document.getElementById(nav_items[i].id).classList.remove("is-active");
    //     }
    // }
}